import React from "react";

import "./index.scss";
import "./responsive.scss";

export default () => {
	return (
		<section className="process-container">
			<section className="intro-content grid">
				<h2>Mr Kirilov's Approach</h2>
				<p>
					We all know how difficult and intimidating it can be to
					launch a successful product. I try to ease that curve by
					providing transparency, creative problem solving and placing
					the needs of the customer at the centre of my work.
				</p>
			</section>
			<section className="content">
				<h3>Development Process</h3>
				<div className="process">
					<div>
						<h4>Brief</h4>
						<p>
							Determine if parties are suitable for the project.
						</p>
					</div>
					<div>
						<h4>On Board</h4>
						<p>Determine what goals the project needs to fulfil</p>
					</div>
					<div>
						<h4>Plan</h4>
						<p>
							Define content, design language, and the project
							structure
						</p>
					</div>
					<div>
						<h4>Design</h4>
						<p>Create mockups and shape the user experience</p>
					</div>
					<div>
						<h4>Develop</h4>
						<p>
							Bring the project to live by glueing all pieces
							together with code
						</p>
					</div>

					<div>
						<h4>Launch</h4>
						<p>
							Prepare and deploy the project to production
							environment
						</p>
					</div>
				</div>
			</section>
		</section>
	);
};
