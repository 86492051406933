import React from "react";

import LayoutTemplate from "../components/templates/layout";
import SeoElement from "../components/elements/seo";
import GreetModule from "../components/modules/greet";
import ServicesModule from "../components/modules/services";
import ProcessModule from "../components/modules/process";
import EngageModule from "../components/modules/engage";

import "./theme/index.scss";

export default () => {
	const contact = {
		emailAddress: "hi@mrkirilov.com",
		header: "Let's work together."
	};

	return (
		<>
			<SeoElement />
			<LayoutTemplate>
				<GreetModule />
				<ServicesModule />
				<ProcessModule />
				<EngageModule className="contact" data={contact} />
			</LayoutTemplate>
		</>
	);
};
