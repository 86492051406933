import React from "react";

import "./index.scss";
import "./responsive.scss";

export default () => {
	return (
		<section className="greet-container grid">
			<section className="profile-content grid">
				<h1>Nice to meet you!</h1>
				<div className="profile" />
			</section>
			<section className="content">
				<p>
					My name is Georgi Kirilov. I am a professional Front End
					Developer based in Stockholm, Sweden. I am passionate about
					web development and improving the lives of others through
					design.
				</p>
				<p>
					I work with clients ranging from individuals and
					small-businesses all the way to large enterprise
					corporations.
				</p>
				<p>
					I am available for new projects.{" "}
					<a className="link" href="mailto:hi@mrkirilov.com">
						EMAIL ME
					</a>
				</p>
			</section>
		</section>
	);
};
