import React from "react";

import "./index.scss";
import "./responsive.scss";

export default () => {
	return (
		<section className="services-container full-screen">
			<section className="content">
				<h2>I specialize in...</h2>
				<section className="services">
					<div>
						<span>Web Applications</span>
					</div>
					<div>
						<span>Static Sites & Templates</span>
					</div>
					<div>
						<span>Web Application Design</span>
					</div>
					<div>
						<span>Mobile App Design</span>
					</div>
					<div>
						<span>Responsive Web Design</span>
					</div>
					<div>
						<span>Wire-framing & Prototyping</span>
					</div>
					<div>
						<span>Mobile & Web Redesign</span>
					</div>
					<div>
						<span>CMS Integration</span>
					</div>
				</section>
				<h3>Or simply put, I create...</h3>
				<section className="simply-services grid">
					<div>
						<span>Incredible web and mobile designs</span>
					</div>
					<div>
						<span>Products that work flawlessly</span>
					</div>
				</section>
			</section>
		</section>
	);
};
