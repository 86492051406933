import React from "react";

import "./index.scss";
import "./responsive.scss";

export default ({ data, className }) => {
	const isImageAvailable = !!data?.image;

	const image = isImageAvailable ? (
		<div className="image-container">
			<img alt="engage" srcSet={data.image} />
		</div>
	) : (
		""
	);

	return (
		<section
			className={"grid engage-container " + (className ? className : "")}
		>
			{image}
			<section className="content only">
				<h3>{data?.header}</h3>
				<a className="cta-link" href="mailto:hi@mrkirilov.com">
					{data?.emailAddress}
				</a>
			</section>
		</section>
	);
};
